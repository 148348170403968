.game-menu {
  position: relative;
  padding: ($grid-gutter-width*3) 0;
  
  @include media-breakpoint-up(lg) {
    padding: ($grid-gutter-width*3) 10%;
  }
  
  @include media-breakpoint-up(xl) {
    padding: ($grid-gutter-width*3) 15%;
  }
  
  .game-menu__header {
    position: relative;
    z-index: $z-index-medium;
    padding: ($grid-gutter-width) 0;
    text-align: center;
    margin: 0;
    
    @include media-breakpoint-up(sm) {
      padding: ($grid-gutter-width) 15%;
    }
    
    @include media-breakpoint-up(md) {
      padding: ($grid-gutter-width*2) 25%;
    }
    
    @include media-breakpoint-up(lg) {
      padding: ($grid-gutter-width*2) 25%;
      margin: 0 0 $grid-gutter-width 0;
    }
    
    @include media-breakpoint-up(xl) {
      padding: ($grid-gutter-width*2) 20%;
    }
  }
  
    
  .game-menu__detail {
    position: absolute;
    z-index: $z-index-low;
    top: 80px;
    left: 50px;
    width: 100px;
    background-size: auto 100%;
    
    @include media-breakpoint-down(xs) {
      top: 40px;
      left: 0;
    }
    
    @include media-breakpoint-up(md) {
      top: 120px;
      left: 150px;
      width: 150px;
    }
    
    svg {
      width: 80%;
      height: auto;
      display: block;
      margin: 0 auto;
      
      #line {
        path {
          animation-name: fade-out-and-in;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-delay: 1s;
          
          @for $i from 1 through 20 {
            &:nth-child(#{$i}) {
              animation-delay: (100ms * $i);
            }
          }
        }
      }
    }
  }

  .game-menu__circles {
    position: absolute;
    z-index: $z-index-low;
    top: 40px;
    right: 0;
    width: 200px;
    
    @include media-breakpoint-down(xs) {
      right: -80px;
      top: 0;
    }
    
    @include media-breakpoint-up(md) {
      top: 0;
      right: -30px;
      width: 430px;
    }
    
    @include media-breakpoint-up(lg) {
      top: 80px;
      right: 0;
    }
    
    .circle-1 {
      position: absolute;
      top: 0;
      left: 0;
      
      @include media-breakpoint-down(xs) {
        display: none;
      }
      
      svg {
        width: 80px;
        height: auto;
        
        @include media-breakpoint-up(md) {
          width: 160px;
        }
      }
      
      #circle-rings {

        @for $i from 1 through 5 {
          #ring-#{$i} {
            animation-name: fade-out-and-in;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-delay: (100ms * $i);
          }
        }
      }
    }
    
    .circle-2 {
      position: absolute;
      top: 0;
      right: 0;
      
      svg {
        width: 90px;
        height: auto;
        animation-name: spin;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        
        @include media-breakpoint-up(md) {
          width: 180px;
        }
      }
    }
    
    .circle-3 {
      margin-top: 50px;
      display: block;
      
      svg {
        width: 150px;
        height: auto;
        display: block;
        margin: 0 auto;
        
        @include media-breakpoint-up(md) {
          width: 240px;
        }
      }
    }
  }
  
  .game-menu__game {
    display: flex;
    align-items: center;
    position: relative;
    margin: $grid-gutter-width 0;
    
    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
      margin: ($grid-gutter-width*2) 0;
    }
    
    @include media-breakpoint-up(md) {
      margin: ($grid-gutter-width/2) 0;
    }
    
    &.game-menu__game--share-that-photo {
      margin-bottom: ($grid-gutter-width/2);
      
      @include media-breakpoint-up(md) {
        margin-top: -($grid-gutter-width/2);
      }
      
      .game-menu__game__detail {
        order: 1;
      }
    
      .game-menu__game__image {
        order: 2;
      }
      
      .game-menu__game__body {
        order: 3;
      }
    }
    
    .game-menu__game__detail {
      width: 150px;
      background-size: auto 100%;
      
      @include media-breakpoint-down(xs) {
        display: none;
      }
      
      svg {
        width: 80%;
        height: auto;
        display: block;
        margin: 0 auto;
      }
      
      #line {
        path {
          animation-name: fade-out-and-in;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-delay: 1s;
          
          @for $i from 1 through 20 {
            &:nth-child(#{$i}) {
              animation-delay: (100ms * $i);
            }
          }
        }
      }
    }
    
    .game-menu__game__image {
      width: 100%;
      
      @include media-breakpoint-down(xs) {
        padding: 0 $grid-gutter-width;
      }
      
      @include media-breakpoint-up(sm) {
        width: 50%;
        padding-right: $grid-gutter-width;
      }
      
      @include media-breakpoint-up(md) {
        width: 35%;
      }
      
      img {
        width: 100%;
        height: auto;
      }
    }
    
    .game-menu__game__body {
      width: 100%;
      padding: 0;
      
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
      
      @include media-breakpoint-up(sm) {
        width: calc(75% - 180px);
      }
      
      @include media-breakpoint-up(md) {
        width: calc(75% - 180px);
        padding-right: ($grid-gutter-width*2);
      }
      
      h2 {
        @include font-display-extra-bold($font-size-medium, 1.2);
      }
      
      .button {
        padding: 10px 30px;
        color: map-get($theme-colours, white);
      }
    }
  }
}
