#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  min-height: $header-height-xs;
  display: flex;
  align-items: flex-end;
  z-index: $z-index-top;
  
  @include media-breakpoint-up(sm) {
    min-height: $header-height-sm;
  }
  
  @include media-breakpoint-up(md) {
    min-height: $header-height-md;
  }
  
  @include media-breakpoint-up(lg) {
    
  }
  
  @include media-breakpoint-up(xl) {
    min-height: $header-height;
  }
  
  &.is-scrolled {
    .header__body {
      .header__brand {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  
  .header__body {
    display: flex;
    align-items: center;
    
    .header__main {
      order: 2;
      width: 60%;
      text-align: center;
    }
    
    .header__brand {
      display: block;
      margin: 0 auto;
      width: 160px;
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
      
      @include media-breakpoint-up(sm) {
        width: 190px;
      }
      
      &:hover,
      &:active {
        opacity: 0.8;
        text-decoration: none;
      }
      
      img {
        width: 100%;
        height: auto;
      }
    }
    
    .header__back {      
      @include font-black($font-size-normal, 1);
    }

    .header__menu {
      position: relative;
      border: none;
      padding: 0;
      margin: 0;
      text-align: left;
      //text-indent: -9999px;
      width: 50px;
      height: 50px;
      background: transparent;
      
      span {
        position: relative;
        z-index: $z-index-medium;
        display: block;
        width: 100%;
        height: 100%;
        background: url(../../images/icon-menu.svg) no-repeat center center;
        background-size: 60% auto;
        overflow: hidden;
        text-indent: -9999px;
      }
      
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        display: block;
        width: 50px;
        height: 50px;
        margin-top: -(50px/2);
        margin-left: -(50px/2);
        border-radius: 50%;
        background: map-get($theme-colours, accent);
        z-index: $z-index-low;
        transform: scale(0);
        opacity: 0;
        transition: opacity 0.25s ease-in-out, transform 0.25s ease-out;
      }
      
      &:hover,
      &:focus {
        outline: none;
        cursor: pointer;
        
        &::before {
          transform: scale(1);
          opacity: 1;
          transition: opacity 0.15s ease-in-out, transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
      }
    }
    
    .header__left {
      order: 1;
      width: 20%;
    }
    
    .header__right {
      order: 3;
      width: 20%;
      display: flex;
      justify-content: flex-end;
    }
    
    .header__finish {
      order: 3;
      width: 20%;
      text-align: right;
      
      @include font-black($font-size-normal, 1);
    }
  }
}
