@import 'common/variables';

@import '~bootstrap/scss/bootstrap';

@import 'common/animations';
@import 'common/global';
@import 'common/type';
@import 'common/buttons';

@import 'components/header';
@import 'components/who-can-help';
@import 'components/picker-menu';
@import 'components/summary';

@import 'components/game-menu';

@import 'components/photo-journey';

@import 'components/technology-plan';
@import 'components/add-rule';
@import 'components/tech-plan-modal';
@import 'components/tech-summary';
