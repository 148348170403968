.button {
  border: none;
  position: relative;
  background: map-get($theme-colours, accent);
  color: map-get($theme-colours, primary);
  text-align: center;
  padding: ($grid-gutter-width/2);
  border-radius: ($grid-gutter-width/2);
  transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
  
  @include font-extra-bold($font-size-normal, 1);
  
  @include media-breakpoint-up(sm) {
    @include font-extra-bold($font-size-medium, 1);
  }
  
  &.button--purple {
    background: map-get($theme-colours, pink);
    color: map-get($theme-colours, white);
  }
  
  &.button--orange {
    background: map-get($theme-colours, orange);
    color: map-get($theme-colours, white);
  }
  
  &.button--disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  
  &.button--wide {
    padding: 5px 10px 5px 5px;
    min-width: 150px;
    border: none;
    margin: 0 10px;

    @include font-extra-bold($font-size-normal, 1);
    
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      
      &::before,
      &::after {
        content: '';
        display: block;
        width: 28px;
        height: 28px;
        background-repeat: no-repeat;
        background-size: 80% auto;
        background-position: center center;
        margin: 0 10px 0 0;
      }
      
      &::after {
        content: none;
        margin: 0 0 0 10px;
      }
    }
    
    &.button--wide--tick {
      span {
        &::before {
          background-image: url(../../images/icon-button-tick.svg);
        }
      }
      
      &:hover,
      &:active {
        span {
          &::before {
            background-image: url(../../images/icon-button-tick-white.svg);
          }
        }
      }
    }
    
    &.button--wide--cross {
      span {
        &::before {
          background-image: url(../../images/icon-button-cross.svg);
          background-size: 65% auto;
        }
      }
      
      &:hover,
      &:active {
        span {
          &::before {
            background-image: url(../../images/icon-button-cross-white.svg);
          }
        }
      }
    }
    
    &.button--wide--add {
      span {
        &::before {
          background-image: url(../../images/icon-button-add.svg);
        }
      }
      
      &:hover,
      &:active {
        span {
          &::before {
            background-image: url(../../images/icon-button-add-white.svg);
          }
        }
      }
    }
    
    &.button--wide--arrow-right {
      padding: 5px 5px 5px 10px;
      
      span {
        &::before {
          content: none;
        }
        
        &::after {
          content: '';
          background-image: url(../../images/icon-button-arrow-right.svg);
        }
      }
      
      &:hover,
      &:active {
        span {
          &::after {
            background-image: url(../../images/icon-button-arrow-right-white.svg);
          }
        }
      }
    }
    
    &.button--wide--print {
      span {
        &::before {
          background-image: url(../../images/icon-button-print.svg);
        }
      }
      
      &:hover,
      &:active {
        span {
          &::before {
            background-image: url(../../images/icon-button-print-white.svg);
          }
        }
      }
    }
    
    &.button--disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: map-get($theme-colours, pink);
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    transform: scale(0);
    border-radius: ($grid-gutter-width/2);
  }
  
  span {
    position: relative;
  }
  
  &:hover,
  &:focus {
    outline: none;
    color: map-get($theme-colours, white);
    
    &::before {
      transform: scale(1);
    }
  }
}
