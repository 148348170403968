.prompt-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z-index-top;
  animation-name: fade-modal-background-in;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  
  @include media-breakpoint-up(sm) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.prompt-modal {
  background: map-get($theme-colours, white);
  padding: ($grid-gutter-width);
  text-align: center;
  height: auto;
  margin: ($grid-gutter-width/2);
  border-radius: 15px;
  
  @include media-breakpoint-up(sm) {
    width: 600px;
    padding: ($grid-gutter-width*2);
  }
  
  .prompt-modal__header {
    
  }
  
  .prompt-modal__body {
    padding: $grid-gutter-width 0;
  }
  
  .prompt-modal__footer {
    
  }
}
