$summary-list-arrow-width: 100px;
$summary-list-image-width: 240px;

.summary {
  margin: $header-height 0 ($grid-gutter-width*2) 0;
  
  .summary__header {
    text-align: center;
    padding: 0;
    margin-bottom: ($grid-gutter-width*2);
  }
}

.summary-list {
  list-style: none;
  padding: 0 10%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  
  li {
    padding: 0;
    margin: ($grid-gutter-width/2) 0;
    width: 100%;
  }
  
  .summary-list__item {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    
    .summary-list__item__scenario {
      width: 30%;
    }
    
    .summary-list__item__answer {
      position: relative;
      width: calc(70% - #{$summary-list-arrow-width});
      background: map-get($theme-colours, pink);
      border-radius: 25px;
      overflow: hidden;
      min-height: 240px;
      
      .summary-list__item__answer__image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: -5px;
        width: $summary-list-image-width;
        padding: $grid-gutter-width 0 0 0;
        display: flex;
        //flex-flow: column;
        //justify-content: flex-end;
        
        svg {
          width: 100%;
          height: auto;
        }
      }
      
      .summary-list__item__answer__body {
        padding: $grid-gutter-width;
        padding-left: $summary-list-image-width;
        color: map-get($theme-colours, white);
        
        .summary-list__item__answer__title {
          @include font-bold($font-size-medium, 1.2);
        }
      }
    }
    
    .summary-list__item__arrow {
      display: block;
      position: absolute;
      top: 50%;
      left: 30%;
      width: $summary-list-arrow-width;
      height: 60px;
      margin-top: -(60px/2);
      margin-left: -30px;
      background: url(../../images/icon-arrow-right.svg) no-repeat center center;
      background-size: auto 80%;
    }
  }
}
