.tech-plan {
  position: relative;
  z-index: $z-index-medium;
  padding: ($grid-gutter-width*2) 0 ($grid-gutter-width*4) 0;
  
  @include media-breakpoint-up(sm) {
    padding: ($grid-gutter-width*2) 0;
  }
  
  @include media-breakpoint-up(md) {
    padding: ($grid-gutter-width*3) 0;
  }
  
  @include media-breakpoint-up(xl) {
    padding: ($grid-gutter-width*5) 0;
  }
  
  .tech-plan__header {
    text-align: center;
    margin: 0 0 ($grid-gutter-width*2) 0
  }
  
  .tech-plan__body {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    
    @include media-breakpoint-up(md) {
      margin: 0;
    }
    
    .tech-plan__body__section {
      width: 50%;
      padding: 0 5px;
      
      @include media-breakpoint-up(sm) {
        width: 25%;
      }
      
      @include media-breakpoint-up(md) {
        padding: 0 ($grid-gutter-width/2);
      }
      
      &.tech-plan__body__section--all-day {
        width: 100%;
        margin-bottom: $grid-gutter-width;
        
        ul {
          display: flex;
          flex-wrap: wrap;
          margin: 0 (-($grid-gutter-width/2));
          justify-content: center;
          
          li {
            width: 25%;
            
            .tech-plan__body__section__rule {
              margin-left: ($grid-gutter-width/2);
              margin-right: ($grid-gutter-width/2);
            }
          }
        }
      }
      
      .tech-plan__body__section__icon {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: center;
        min-height: 80px;
        margin: 0 0 5px 0;
        
        @include media-breakpoint-up(md) {
          min-height: 120px;
          margin: 0 0 ($grid-gutter-width/2) 0;
        }
        
        @include media-breakpoint-up(lg) {
          min-height: 120px;
        }
        
        svg {
          max-width: 30px;
          height: auto;
          
          @include media-breakpoint-up(md) {
            max-width: 60px;
          }
          
          @include media-breakpoint-up(lg) {
            max-width: 100px;
          }
        }
        
        h2 {
          width: 100%;
          display: block;
          
          @include font-display-bold($font-size-normal, 1.2);
          
          @include media-breakpoint-up(md) {
            @include font-display-bold($font-size-medium, 1.2);
          }
        }
      }
      
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        
        li {
          padding: 0;
          margin: 0;
          
          .tech-plan__body__section__rule {
            padding: ($grid-gutter-width/2);
            margin: 0 0 ($grid-gutter-width/2) 0;
            border-radius: 15px;
            text-align: center;
            color: map-get($theme-colours, white);
            background: map-get($theme-colours, primary);
            
            @include font-regular($font-size-normal, 1.3);
            
            @include media-breakpoint-up(lg) {
              @include font-regular($font-size-medium, 1.3);
            }
            
            &.orange {
              background-color: map-get($theme-colours, orange);
            }
            
            &.purple {
              background-color: map-get($theme-colours, purple-light);
            }
            
            &.green {
              background-color: map-get($theme-colours, green);
            }
            
            &.red {
              background-color: map-get($theme-colours, red);
            }
            
            &.blue {
              background-color: map-get($theme-colours, blue);
            }
            
            &.grey {
              border: solid 2px map-get($theme-colours, light-grey);
              color: map-get($theme-colours, light-grey);
              background-color: transparent;
              
              &:hover {
                border-color: map-get($theme-colours, primary);
                color: map-get($theme-colours, primary);
                cursor: pointer;
              }
            }
            
            strong {
              .icon {
                display: inline-block;
                margin-right: 7px;
                transform: translateY(-2px);
                
                svg, 
                * {
                  fill: currentColor;
                }
                
                svg {
                  max-width: 22px;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }
  
  .tech-plan__footer {
    margin: ($grid-gutter-width*2) 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .button {
      padding: 10px 15px 10px 15px;
      
      @include media-breakpoint-down(xs) {
        @include font-display-bold($font-size-small, 1);
      }
    }
    
    @include media-breakpoint-down(xs) {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: map-get($theme-colours, white);
      padding: ($grid-gutter-width/2) 0;
      height: auto;
    }
  }
}
