/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$spacer: 2rem;
$header-height: 110px;
$header-height-md: 90px;
$header-height-sm: 80px;
$header-height-xs: 60px;

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1400px
);

$container-max-widths: (
  sm: 720px,
  md: 960px,
  lg: 1140px,
  xl: 1340px
);

$theme-colours: (
  primary: #070707, // Almost Black
  accent: #f7ce15, // Yellow
  pink: #c64098,  
  purple: #593ee8,
  purple-light: #c02cf1,
  orange: #f05a2b, //#f1592c
  orange-dark: #f1592c,
  green: #49c214,
  red: #f12c43,
  blue: #2c4cf1,
  white: #fff, 
  black: #000, 
  very-dark-grey: #080907,
  dark-grey: #353535,
  medium-grey: #7c7c7c,
  light-grey: #d3d3d3,
  very-light-grey: #f7f7f3, 
  error: #a22727,
  success: #00811f,
);

$link-color: map-get($theme-colours, black);

// Font Sizes

$font-size-root: 18px;
$font-size-root-md: 18px;
$font-size-root-xs: 18px;

$font-size-tiny: 0.75rem;
$font-size-small: 0.8rem;
$font-size-normal: 1rem;
$font-size-medium: 1.3rem;
$font-size-large: 2.2rem;
$font-size-extra-large: 2rem;

// Z-Index

$z-index-bottom: 1;
$z-index-low: 300;
$z-index-medium: 500;
$z-index-high: 700;
$z-index-top: 900;

// Fonts

$base-font-family: "Muli", "Helvetica Neue", "Arial", sans-serif;
$display-font-family: $base-font-family;

@mixin font-light($font-size: 1rem, $line-height-multiplier: 1.4) {
  font-family: $base-font-family;
  font-size: $font-size;
  line-height: $font-size * $line-height-multiplier;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-regular($font-size: 1rem, $line-height-multiplier: 1.4) {
  font-family: $base-font-family;
  font-size: $font-size;
  line-height: $font-size * $line-height-multiplier;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-medium($font-size: 1rem, $line-height-multiplier: 1.4) {
  font-family: $base-font-family;
  font-size: $font-size;
  line-height: $font-size * $line-height-multiplier;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-bold($font-size: 1rem, $line-height-multiplier: 1.4) {
  font-family: $base-font-family;
  font-size: $font-size;
  line-height: $font-size * $line-height-multiplier;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-extra-bold($font-size: 1rem, $line-height-multiplier: 1.4) {
  font-family: $base-font-family;
  font-size: $font-size;
  line-height: $font-size * $line-height-multiplier;
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-black($font-size: 1rem, $line-height-multiplier: 1.4) {
  font-family: $base-font-family;
  font-size: $font-size;
  line-height: $font-size * $line-height-multiplier;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-display-regular($font-size: 1rem, $line-height-multiplier: 1.4) {
  font-family: $display-font-family;
  font-size: $font-size;
  line-height: $font-size * $line-height-multiplier;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-display-bold($font-size: 1rem, $line-height-multiplier: 1.4) {
  font-family: $display-font-family;
  font-size: $font-size;
  line-height: $font-size * $line-height-multiplier;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-display-extra-bold($font-size: 1rem, $line-height-multiplier: 1.4) {
  font-family: $display-font-family;
  font-size: $font-size;
  line-height: $font-size * $line-height-multiplier;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;
