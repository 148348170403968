.who-can-help {
  display: flex;
  flex-wrap: wrap;
  
  .who-can-help__header {
    width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: ($grid-gutter-width*1.5) ($grid-gutter-width/2) 0 0;
    
    @include media-breakpoint-up(md) {
      width: 40%;
    }
    
    @include media-breakpoint-up(lg) {
      padding-top: 0;
      align-items: center;
    }
    
    .who-can-help__header__inner {
      position: relative;
      z-index: $z-index-medium;
      width: 100%;
      margin-bottom: ($grid-gutter-width);
      
      @include media-breakpoint-up(sm) {
        margin-bottom: ($grid-gutter-width*2);
      }
      
      @include media-breakpoint-up(md) {
        margin-bottom: 30%;
      }
    }
    
    .who-can-help__header__selected-icon {
      height: 280px;
      position: relative;
      
      @include media-breakpoint-down(xs) {
        display: none;
      }
      
      .who-can-help__header__selected-icon__wrapper {
        overflow: hidden;
        height: 100%;
        padding-top: 30px;
        
        svg {
          @include media-breakpoint-down(md) {
            max-width: 180px;
            height: auto;
          }
        }
      }
      
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        display: block;
        height: 5px;
        background: url(../../images/divider.svg) no-repeat bottom center;
        background-size: auto 100%;
      }
    }
    
    .who-can-help__header__question-mark {
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
    
    .who-can-help__header__title {
      margin: 0;
      padding: 0 15%;
      
      @include font-black($font-size-medium, 1.2);
      
      @include media-breakpoint-up(sm) {
        margin: $grid-gutter-width 0 0 0;
        padding: 0 20%;
        
        @include font-black(($font-size-large*0.8), 1.2);
      }
      
      @include media-breakpoint-up(md) {
        padding: 0;
      }
      
      @include media-breakpoint-up(lg) {
        @include font-black($font-size-large, 1.2);
      }
    }
    
    .pyramid {
      display: block;
      width: 100%;
      height: 100%;
      background: url(../../images/pyramid.svg) no-repeat center center;
      background-size: 100% 100%;
    }
    
    .who-can-help__header__detail {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 0;
      bottom: 0;
      display: block;
      width: 430px;
      height: 470px;
      margin-top: -(470px/2);
      margin-left: -(430px/2);
      z-index: $z-index-low;
    }
    
    .blob {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 0;
      bottom: 0;
      display: block;
      width: 220px;
      height: 200px;
      margin-top: -(200px/2);
      margin-left: -(220px/2);
      background-size: 100% auto;
      z-index: $z-index-low;
      
      @include media-breakpoint-up(sm) {
        width: 320px;
        height: 260px;
        margin-top: -(260px/2);
        margin-left: -(320px/2);
      }
      
      @include media-breakpoint-up(lg) {
        width: 370px;
        height: 320px;
        margin-top: -(320px/2);
        margin-left: -(370px/2);
      }
      
      @include media-breakpoint-up(xl) {
        width: 470px;
        height: 420px;
        margin-top: -(420px/2);
        margin-left: -(470px/2);
      }
      
      svg {
        width: 100%;
        height: 100%;
        overflow: visible;
      }
    }
  }
  
  .who-can-help__main {
    width: 100%;
    
    @include media-breakpoint-up(md) {
      width: 60%;
    }
  }
}
