@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes tilt {
  0% { transform: rotate(-10deg); }
  100% { transform: rotate(10deg); }
}

@keyframes fade-out-and-in {
  0% { opacity: 1; }
  50% { opacity: 1; }
  75% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-out-and-in-long {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes scale-up-and-down {
  0% { transform: scale(1); }
  50% { transform: scale(1); }
  75% { transform: scale(0.8); }
  100% { transform: scale(1); }
}

@keyframes popup {
  0% { transform: scale(0); }
  20% { transform: scale(1); }
  100% { transform: scale(1); }
}

@keyframes pyramid-fade {
  0% { opacity: 0; transform: translateY(100px); }
  50% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-100px); }
}

@keyframes flicker {
  0% { transform: rotate(-2deg); }
  100% { transform: rotate(2deg); }
}

@keyframes left-spray-top {
  0% { transform: translate(0, 0); }
  100% { transform: translate(-20px, -5px); opacity: 0; }
}

@keyframes left-spray-middle {
  0% { transform: translate(0, 0); }
  100% { transform: translate(-20px, 5px); opacity: 0; }
}

@keyframes left-spray-bottom {
  0% { transform: translate(0, 0); }
  100% { transform: translate(-20px, 20px); opacity: 0; }
}

@keyframes right-spray-top {
  0% { transform: translate(0, 0); }
  100% { transform: translate(20px, 5px); opacity: 0; }
}

@keyframes right-spray-middle {
  0% { transform: translate(0, 0); }
  100% { transform: translate(20px, -5px); opacity: 0; }
}

@keyframes right-spray-bottom {
  0% { transform: translate(0, 0); }
  100% { transform: translate(20px, -20px); opacity: 0; }
}

@keyframes smoke-up {
  0% { transform: translate(0, 20px); opacity: 0; }
  50% { transform: translate(0, 0); opacity: 1; }
  100% { transform: translate(0, -20px); opacity: 0; }
}

@keyframes flash {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes scale-and-fade {
  0% { opacity: 1; transform: scale(1); }
  100% { opacity: 0; transform: scale(2); }
}

@keyframes photo-move {
  0% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: cubic-bezier(.25,.25,.75,.75);}
  100% {transform:translate(0px, 0px) scale(1) rotateX(30deg) rotateY(40deg) rotateZ(60deg);}
}

@keyframes fade-modal-background-in {
  0% { background: rgba(255, 255, 255, 0); }
  100% { background: rgba(255, 255, 255, 0.6); }
}

/* stylelint-disable */
@keyframes poke {
  0% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  3.33% {transform:translate(0px, 0px) scale(1.2031) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  6.67% {transform:translate(0px, 0px) scale(1.227) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  10% {transform:translate(0px, 0px) scale(1.1569) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  13.33% {transform:translate(0px, 0px) scale(1.067) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  16.67% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  20% {transform:translate(0px, 0px) scale(0.968) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  23.33% {transform:translate(0px, 0px) scale(0.9642) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  26.67% {transform:translate(0px, 0px) scale(0.9753) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  30% {transform:translate(0px, 0px) scale(0.9895) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  33.33% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  36.67% {transform:translate(0px, 0px) scale(1.005) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  40% {transform:translate(0px, 0px) scale(1.0056) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  43.33% {transform:translate(0px, 0px) scale(1.0039) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  46.67% {transform:translate(0px, 0px) scale(1.0017) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  50% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  53.33% {transform:translate(0px, 0px) scale(0.9992) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  56.67% {transform:translate(0px, 0px) scale(0.9991) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  60% {transform:translate(0px, 0px) scale(0.9994) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  63.33% {transform:translate(0px, 0px) scale(0.9997) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  66.67% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  70% {transform:translate(0px, 0px) scale(1.0001) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  73.33% {transform:translate(0px, 0px) scale(1.0001) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  76.67% {transform:translate(0px, 0px) scale(1.0001) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  80% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  83.33% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  86.67% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  90% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  93.33% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  96.67% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
  100% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;}
}
/* stylelint-enable */

@keyframes photo-move {
  0% {transform:translate(0px, 0px) scale(1) rotateX(0) rotateY(0) rotateZ(0);animation-timing-function: cubic-bezier(.25,.25,.75,.75);}
  100% {transform:translate(0px, 0px) scale(1) rotateX(-0) rotateY(-360deg) rotateZ(-180deg);}
  
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes loading {
  0% {width: 0%;}
  100% {width: 100%;}
}


@keyframes photo-move-old {
  0% { transform: rotate(0); }
  50% { transform: translate(0, 0); opacity: 1; }
  100% { transform: translate(0, -20px); opacity: 0; }
}

@keyframes zoom-out {
  from {
    transform: scale(1);
    opacity: 1;
  }
  
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.pyramid {
  animation: pyramid-fade 10s infinite linear;
}

.blob {
  opacity: 0;
  animation-name: spring-in;
  animation-duration: 3000ms;
  animation-delay: 1000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.blob #blob-wrapper {
  animation: jello 20s infinite ease-in-out;
  animation-direction: alternate;
  transform-origin: center center;
}

#icon-question-mark {
  #ring {
    transform-origin: center center;
    animation: spin 5s infinite linear;
  }
}

#icon-ad {
  #popup #popup-inner {
    transform-box: fill-box;
    transform-origin: center center;
    animation: popup 1.5s infinite ease-in-out;
    animation-direction: alternate-reverse;
  }
}

#icon-fire {
  #flame-1 #inner {
    transform-box: fill-box;
    transform-origin: center center;
    animation: flicker 0.5s infinite ease-in-out;
    animation-direction: alternate-reverse;
  }
  
  #flame-2 #inner {
    transform-box: fill-box;
    transform-origin: center center;
    animation: flicker 0.5s infinite ease-in-out;
    animation-direction: alternate;
  }
  
  #flame-3 #inner {
    transform-box: fill-box;
    transform-origin: bottom center;
    animation: flicker 0.5s infinite ease-in-out;
    animation-direction: alternate-reverse;
  }
}

#icon-scary,
#icon-lost {
  overflow: visible;
  
  #sweat-left {
    path {
      &:nth-child(1) {
        animation: left-spray-top 1s infinite ease-out;
      }
      
      &:nth-child(2) {
        animation: left-spray-middle 1s infinite ease-out;
      }
      
      &:nth-child(3) {
        animation: left-spray-bottom 1s infinite ease-out;
      }
    }
  }
  
  #sweat-right {
    path {
      &:nth-child(1) {
        animation: right-spray-top 1s infinite ease-out;
      }
      
      &:nth-child(2) {
        animation: right-spray-middle 1s infinite ease-out;
      }
      
      &:nth-child(3) {
        animation: right-spray-bottom 1s infinite ease-out;
      }
    }
  }
}

#icon-crash {
  #smoke {
    path {
      animation: smoke-up 3s infinite linear;
    }
  }
}

@keyframes spring-in {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  1% {
    -webkit-transform: scale(0.086484529651881, 0.179427456557767);
            transform: scale(0.086484529651881, 0.179427456557767);
  }
  2% {
    -webkit-transform: scale(0.292554252432152, 0.545376716848168);
            transform: scale(0.292554252432152, 0.545376716848168);
  }
  3% {
    -webkit-transform: scale(0.545376716848168, 0.894404964543183);
            transform: scale(0.545376716848168, 0.894404964543183);
  }
  4% {
    -webkit-transform: scale(0.788551391390369, 1.120376016086594);
            transform: scale(0.788551391390369, 1.120376016086594);
  }
  5% {
    -webkit-transform: scale(0.985835941661639, 1.205153326404069);
            transform: scale(0.985835941661639, 1.205153326404069);
  }
  6% {
    -webkit-transform: scale(1.120376016086594, 1.184807461549862);
            transform: scale(1.120376016086594, 1.184807461549862);
  }
  7% {
    -webkit-transform: scale(1.191045818185745, 1.113400777234015);
            transform: scale(1.191045818185745, 1.113400777234015);
  }
  8% {
    -webkit-transform: scale(1.207445230574602, 1.037247338712057);
            transform: scale(1.207445230574602, 1.037247338712057);
  }
  9% {
    -webkit-transform: scale(1.184807461549862, 0.983312126349848);
            transform: scale(1.184807461549862, 0.983312126349848);
  }
  10% {
    -webkit-transform: scale(1.139672272493081, 0.959151493096828);
            transform: scale(1.139672272493081, 0.959151493096828);
  }
  11% {
    -webkit-transform: scale(1.086773838727188, 0.959207005548428);
            transform: scale(1.086773838727188, 0.959207005548428);
  }
  12% {
    -webkit-transform: scale(1.037247338712057, 0.972534530793938);
            transform: scale(1.037247338712057, 0.972534530793938);
  }
  13% {
    -webkit-transform: scale(0.998010256157525, 0.98880159678671);
            transform: scale(0.998010256157525, 0.98880159678671);
  }
  14% {
    -webkit-transform: scale(0.972031419546276, 1.001379435012348);
            transform: scale(0.972031419546276, 1.001379435012348);
  }
  15% {
    -webkit-transform: scale(0.959151493096828, 1.00783265522376);
            transform: scale(0.959151493096828, 1.00783265522376);
  }
  16% {
    -webkit-transform: scale(0.957140100254271, 1.008821093113781);
            transform: scale(0.957140100254271, 1.008821093113781);
  }
  17% {
    -webkit-transform: scale(0.96274018852263, 1.006488198218788);
            transform: scale(0.96274018852263, 1.006488198218788);
  }
  18% {
    -webkit-transform: scale(0.972534530793938, 1.003092956925791);
            transform: scale(0.972534530793938, 1.003092956925791);
  }
  19% {
    -webkit-transform: scale(0.983552526255917, 1.000221414749495);
            transform: scale(0.983552526255917, 1.000221414749495);
  }
  20% {
    -webkit-transform: scale(0.993604544681563, 0.998573305048547);
            transform: scale(0.993604544681563, 0.998573305048547);
  }
  21% {
    -webkit-transform: scale(1.001379435012348, 0.998133854506318);
            transform: scale(1.001379435012348, 0.998133854506318);
  }
  22% {
    -webkit-transform: scale(1.006367469874671, 0.998500975623806);
            transform: scale(1.006367469874671, 0.998500975623806);
  }
  23% {
    -webkit-transform: scale(1.00867876840649, 0.999191084459488);
            transform: scale(1.00867876840649, 0.999191084459488);
  }
  24% {
    -webkit-transform: scale(1.008821093113781, 0.999833501948367);
            transform: scale(1.008821093113781, 0.999833501948367);
  }
  25% {
    -webkit-transform: scale(1.007486437375814, 1.000240340703161);
            transform: scale(1.007486437375814, 1.000240340703161);
  }
  26% {
    -webkit-transform: scale(1.005378103626964, 1.000385276149533);
            transform: scale(1.005378103626964, 1.000385276149533);
  }
  27% {
    -webkit-transform: scale(1.003092956925791, 1.000339232175458);
            transform: scale(1.003092956925791, 1.000339232175458);
  }
  28% {
    -webkit-transform: scale(1.001059752569101, 1.000203385122328);
            transform: scale(1.001059752569101, 1.000203385122328);
  }
  29% {
    -webkit-transform: scale(0.999525049489696, 1.000062534362191);
            transform: scale(0.999525049489696, 1.000062534362191);
  }
  30% {
    -webkit-transform: scale(0.998573305048547, 0.999964849826148);
            transform: scale(0.998573305048547, 0.999964849826148);
  }
  31% {
    -webkit-transform: scale(0.998166624576185, 0.999922697095762);
            transform: scale(0.998166624576185, 0.999922697095762);
  }
  32% {
    -webkit-transform: scale(0.998191241770966, 0.999924761163581);
            transform: scale(0.998191241770966, 0.999924761163581);
  }
  33% {
    -webkit-transform: scale(0.998500975623806, 0.999950419122189);
            transform: scale(0.998500975623806, 0.999950419122189);
  }
  34% {
    -webkit-transform: scale(0.998951615476555, 0.999980662109647);
            transform: scale(0.998951615476555, 0.999980662109647);
  }
  35% {
    -webkit-transform: scale(0.99942365059167, 1.000003563281379);
            transform: scale(0.99942365059167, 1.000003563281379);
  }
  36% {
    -webkit-transform: scale(0.999833501948367, 1.000014970213524);
            transform: scale(0.999833501948367, 1.000014970213524);
  }
  37% {
    -webkit-transform: scale(1.000135225951195, 1.000016350718153);
            transform: scale(1.000135225951195, 1.000016350718153);
  }
  38% {
    -webkit-transform: scale(1.00031555507819, 1.000011778605271);
            transform: scale(1.00031555507819, 1.000011778605271);
  }
  39% {
    -webkit-transform: scale(1.000385276149533, 1.000005429970832);
            transform: scale(1.000385276149533, 1.000005429970832);
  }
  40% {
    -webkit-transform: scale(1.00036955135608, 1.000000175788403);
            transform: scale(1.00036955135608, 1.000000175788403);
  }
  41% {
    -webkit-transform: scale(1.000299099745054, 0.999997234930038);
            transform: scale(1.000299099745054, 0.999997234930038);
  }
  42% {
    -webkit-transform: scale(1.000203385122328, 0.999996522266158);
            transform: scale(1.000203385122328, 0.999996522266158);
  }
  43% {
    -webkit-transform: scale(1.000106253265117, 0.999997264764804);
            transform: scale(1.000106253265117, 0.999997264764804);
  }
  44% {
    -webkit-transform: scale(1.000023918238932, 0.999998563845857);
            transform: scale(1.000023918238932, 0.999998563845857);
  }
  45% {
    -webkit-transform: scale(0.999964849826148, 0.999999744931904);
            transform: scale(0.999964849826148, 0.999999744931904);
  }
  46% {
    -webkit-transform: scale(0.999930953553718, 1.000000476269022);
            transform: scale(0.999930953553718, 1.000000476269022);
  }
  47% {
    -webkit-transform: scale(0.999919425875407, 1.000000722388812);
            transform: scale(0.999919425875407, 1.000000722388812);
  }
  48% {
    -webkit-transform: scale(0.999924761163581, 1.000000621989317);
            transform: scale(0.999924761163581, 1.000000621989317);
  }
  49% {
    -webkit-transform: scale(0.999940535092441, 1.000000364142579);
            transform: scale(0.999940535092441, 1.000000364142579);
  }
  50% {
    -webkit-transform: scale(0.99996074901389, 1.000000103935755);
            transform: scale(0.99996074901389, 1.000000103935755);
  }
  51% {
    -webkit-transform: scale(0.999980662109647, 0.999999927251821);
            transform: scale(0.999980662109647, 0.999999927251821);
  }
  52% {
    -webkit-transform: scale(0.999997145282538, 0.999999853998891);
            transform: scale(0.999997145282538, 0.999999853998891);
  }
  53% {
    -webkit-transform: scale(1.000008657088084, 0.999999861387962);
            transform: scale(1.000008657088084, 0.999999861387962);
  }
  54% {
    -webkit-transform: scale(1.000014970213524, 0.999999910625746);
            transform: scale(1.000014970213524, 0.999999910625746);
  }
  55% {
    -webkit-transform: scale(1.000016775086596, 0.999999966780841);
            transform: scale(1.000016775086596, 0.999999966780841);
  }
  56% {
    -webkit-transform: scale(1.000015265384358, 1.000000008427682);
            transform: scale(1.000015265384358, 1.000000008427682);
  }
  57% {
    -webkit-transform: scale(1.000011778605271, 1.000000028536839);
            transform: scale(1.000011778605271, 1.000000028536839);
  }
  58% {
    -webkit-transform: scale(1.000007531821609, 1.000000030270993);
            transform: scale(1.000007531821609, 1.000000030270993);
  }
  59% {
    -webkit-transform: scale(1.000003464072008, 1.000000021355454);
            transform: scale(1.000003464072008, 1.000000021355454);
  }
  60% {
    -webkit-transform: scale(1.000000175788403, 1.000000009501385);
            transform: scale(1.000000175788403, 1.000000009501385);
  }
  61% {
    -webkit-transform: scale(0.999997943086903, 0.99999999989862);
            transform: scale(0.999997943086903, 0.99999999989862);
  }
  62% {
    -webkit-transform: scale(0.999996779924779, 0.999999994661554);
            transform: scale(0.999996779924779, 0.999999994661554);
  }
  63% {
    -webkit-transform: scale(0.999996522266158, 0.999999993527534);
            transform: scale(0.999996522266158, 0.999999993527534);
  }
  64% {
    -webkit-transform: scale(0.999996913355979, 0.999999995014962);
            transform: scale(0.999996913355979, 0.999999995014962);
  }
  65% {
    -webkit-transform: scale(0.999997675912265, 0.999999997456225);
            transform: scale(0.999997675912265, 0.999999997456225);
  }
  66% {
    -webkit-transform: scale(0.999998563845857, 0.999999999625199);
            transform: scale(0.999998563845857, 0.999999999625199);
  }
  67% {
    -webkit-transform: scale(0.999999391872285, 1.000000000937729);
            transform: scale(0.999999391872285, 1.000000000937729);
  }
  68% {
    -webkit-transform: scale(1.000000045503658, 1.000000001352395);
            transform: scale(1.000000045503658, 1.000000001352395);
  }
  69% {
    -webkit-transform: scale(1.000000476269022, 1.000000001139136);
            transform: scale(1.000000476269022, 1.000000001139136);
  }
  70% {
    -webkit-transform: scale(1.000000687807928, 1.000000000650779);
            transform: scale(1.000000687807928, 1.000000000650779);
  }
  71% {
    -webkit-transform: scale(1.000000718100621, 1.000000000170632);
            transform: scale(1.000000718100621, 1.000000000170632);
  }
  72% {
    -webkit-transform: scale(1.000000621989317, 0.999999999851502);
            transform: scale(1.000000621989317, 0.999999999851502);
  }
  73% {
    -webkit-transform: scale(1.000000456728239, 0.999999999724764);
            transform: scale(1.000000456728239, 0.999999999724764);
  }
  74% {
    -webkit-transform: scale(1.00000027190648, 0.999999999744926);
            transform: scale(1.00000027190648, 0.999999999744926);
  }
  75% {
    -webkit-transform: scale(1.000000103935755, 0.999999999839136);
            transform: scale(1.000000103935755, 0.999999999839136);
  }
  76% {
    -webkit-transform: scale(0.999999974491714, 0.999999999943276);
            transform: scale(0.999999974491714, 0.999999999943276);
  }
  77% {
    -webkit-transform: scale(0.999999891858109, 1.000000000018919);
            transform: scale(0.999999891858109, 1.000000000018919);
  }
  78% {
    -webkit-transform: scale(0.999999853998891, 1.000000000054265);
            transform: scale(0.999999853998891, 1.000000000054265);
  }
  79% {
    -webkit-transform: scale(0.999999852290513, 1.000000000055976);
            transform: scale(0.999999852290513, 1.000000000055976);
  }
  80% {
    -webkit-transform: scale(0.999999875091735, 1.000000000038668);
            transform: scale(0.999999875091735, 1.000000000038668);
  }
  81% {
    -webkit-transform: scale(0.999999910625746, 1.000000000016565);
            transform: scale(0.999999910625746, 1.000000000016565);
  }
  82% {
    -webkit-transform: scale(0.999999948934096, 0.999999999999035);
            transform: scale(0.999999948934096, 0.999999999999035);
  }
  83% {
    -webkit-transform: scale(0.999999982891666, 0.999999999989729);
            transform: scale(0.999999982891666, 0.999999999989729);
  }
  84% {
    -webkit-transform: scale(1.000000008427682, 0.999999999987969);
            transform: scale(1.000000008427682, 0.999999999987969);
  }
  85% {
    -webkit-transform: scale(1.000000024178711, 0.999999999990925);
            transform: scale(1.000000024178711, 0.999999999990925);
  }
  86% {
    -webkit-transform: scale(1.000000030817137, 0.999999999995506);
            transform: scale(1.000000030817137, 0.999999999995506);
  }
  87% {
    -webkit-transform: scale(1.000000030270993, 0.999999999999484);
            transform: scale(1.000000030270993, 0.999999999999484);
  }
  88% {
    -webkit-transform: scale(1.000000024997425, 1.000000000001836);
            transform: scale(1.000000024997425, 1.000000000001836);
  }
  89% {
    -webkit-transform: scale(1.000000017409911, 1.000000000002528);
            transform: scale(1.000000017409911, 1.000000000002528);
  }
  90% {
    -webkit-transform: scale(1.000000009501385, 1.000000000002084);
            transform: scale(1.000000009501385, 1.000000000002084);
  }
  91% {
    -webkit-transform: scale(1.000000002659794, 1.000000000001161);
            transform: scale(1.000000002659794, 1.000000000001161);
  }
  92% {
    -webkit-transform: scale(0.999999997642536, 1.000000000000276);
            transform: scale(0.999999997642536, 1.000000000000276);
  }
  93% {
    -webkit-transform: scale(0.999999994661554, 0.9999999999997);
            transform: scale(0.999999994661554, 0.9999999999997);
  }
  94% {
    -webkit-transform: scale(0.999999993528801, 0.999999999999482);
            transform: scale(0.999999993528801, 0.999999999999482);
  }
  95% {
    -webkit-transform: scale(0.999999993818591, 0.999999999999531);
            transform: scale(0.999999993818591, 0.999999999999531);
  }
  96% {
    -webkit-transform: scale(0.999999995014962, 0.999999999999711);
            transform: scale(0.999999995014962, 0.999999999999711);
  }
  97% {
    -webkit-transform: scale(0.999999996625079, 0.999999999999904);
            transform: scale(0.999999996625079, 0.999999999999904);
  }
  98% {
    -webkit-transform: scale(0.999999998251508, 1.000000000000041);
            transform: scale(0.999999998251508, 1.000000000000041);
  }
  99% {
    -webkit-transform: scale(0.999999999625199, 1.000000000000103);
            transform: scale(0.999999999625199, 1.000000000000103);
  }
  100% {
    -webkit-transform: scale(1.000000000606774, 1.000000000000103);
            transform: scale(1.000000000606774, 1.000000000000103);
  }
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes jello {
  0% {
    transform: skewX(0) skewY(0);
  }

  25% {
    transform: skewX(-5deg) skewY(-5deg);
  }

  75% {
    transform: skewX(5deg) skewY(5deg);
  }
  
  100% {
    transform: skewX(0) skewY(0);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes shake {
  from,
  to {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

@keyframes shakey-camera {
	0% { transform: translate(2px, 1px) rotate(0deg) scale(1.2); }
	10% { transform: translate(-1px, -2px) rotate(-1deg) scale(1.2); }
	20% { transform: translate(-3px, 0px) rotate(1deg) scale(1.2); }
	30% { transform: translate(0px, 2px) rotate(0deg) scale(1.2); }
	40% { transform: translate(1px, -1px) rotate(1deg) scale(1.2); }
	50% { transform: translate(-1px, 2px) rotate(-1deg) scale(1.2); }
	60% { transform: translate(-3px, 1px) rotate(0deg) scale(1.2); }
	70% { transform: translate(2px, 1px) rotate(-1deg) scale(1.2); }
	80% { transform: translate(-1px, -1px) rotate(1deg) scale(1.2); }
	90% { transform: translate(2px, 2px) rotate(0deg) scale(1.2); }
	100% { transform: translate(1px, -2px) rotate(-1deg) scale(1.2); }
}

.shakey-camera {
	animation-name: shakey-camera;
	animation-duration: 10.8s;
	transform-origin:50% 50%;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}

.stylie {
  animation-name: stylie-transform-keyframes;
  animation-duration: 3000ms;
  animation-delay: 0ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: 0 0;
}
