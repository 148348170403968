$profile-picker-size: 130px;
$profile-picker-size-sm: 90px;

.photo-journey {
  height: 700px;
  width: 100%;
  
  .photo-journey__viewport {
    overflow: hidden;
    width: 100%;
    height: 100%;
    //background: yellow;
  }
  
  .photo-journey__content {
    width: 600%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
  }
  
  .photo-journey__content__section {
    width: 20%;
    height: 100%;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    position: relative;
    
    &.photo-journey__content__section--was-active,
    &.photo-journey__content__section--active {
      .photo-journey__content__section__device {
        z-index: $z-index-medium;
        opacity: 1;
      }
      
      .device {
        z-index: $z-index-medium;

        &.device--phone,        
        &.device--laptop,
        &.device--tablet {
          opacity: 1;
          
          .device__display {
            .device__display__image {
              transform: scale(1);
              transition-delay: 3s;
            }
            
            .device__preloader {
              span {
                width: 100%;
                transition-delay: 0.75s;
              	//animation-name: loading;
              	//animation-duration: 2s;
              	//animation-iteration-count: 1;
              	//animation-timing-function: ease-in-out;
              	//animation-fill-mode: forwards;
              }
            }
          }
        }
      }
    }
    
    .photo-journey__content__section__device {
      position: relative;
      z-index: $z-index-low;
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
      //display: block;
      
      .device__message {
        position: absolute;
        top: 50%;
        left: 100%;
        display: flex;
        align-items: center;
        width: 200px;
        height: 200px;
        margin-top: -(200px/2);
        margin-left: (200px/2) + ($grid-gutter-width/2);
        text-align: center;
        
        @include media-breakpoint-up(lg) {
          width: 260px;
          height: 200px;
          margin-top: -(200px/2);
          margin-left: (286px/2) + $grid-gutter-width;
        }
        
        h1 {
          margin: 0;
          padding: 0;
          
          @include font-extra-bold(($font-size-large*0.8), 1.1);
          
          @include media-breakpoint-up(lg) {
            @include font-extra-bold($font-size-large, 1.1);
          }
        }
      }
      
      .device__detail {
        position: absolute;
        top: 50%;
        right: 0;
        width: 300px;
        height: 350px;
        margin-top: -(350px/2);
        text-align: center;
        z-index: $z-index-low;
        
        @include media-breakpoint-up(lg) {
          width: 400px;
          height: 350px;
          margin-top: -(350px/2);
        }
        
        &::before {
          position: absolute;
          top: 0;
          left: -15%;
          content: '';
          display: block;
          width: 180px;
          height: 180px;
          background: url(../../images/dashed-circle.svg) no-repeat center center;
          background-size: 100% 100%;
        	animation-name: spin;
        	animation-duration: 5s;
        	animation-iteration-count: infinite;
        	animation-timing-function: linear;
        }
        
        .device__detail__image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url(../../images/suzie-medium-shot.png) no-repeat left center;
          background-size: auto 100%;
          
        	@include media-breakpoint-up(lg) {
          	background-position: center center;
        	}
        }
      }
    }
    
    .photo-journey__content__section__options {
      position: relative;
      z-index: $z-index-medium;
      
      .message-prompt {
        max-width: 500px;
        text-align: center;
        padding: 0 ($grid-gutter-width);
        
        h1 {
          margin: 0 0 0 0;
          padding: 0;
          
          @include font-extra-bold($font-size-large, 1);
          
          em {
            display: block;
            font-style: normal;
            margin-top: 15px;
          }
        }
        
        .message-prompt__buttons {
          display: flex;
          justify-content: center;
          margin-top: $grid-gutter-width;
          
          .button {
            margin: 0 10px;
          }
        }
      }
    }
    
    &.photo-journey__content__section--laptop {
      .photo-journey__content__section__dotted-line {
        width: 65%;
      }
    }
    
    &.photo-journey__content__section--tablet {
      .photo-journey__content__section__dotted-line {
        width: 85%;
      }
      
      .photo-wrapper {
        @include media-breakpoint-down(md) {
          padding-top: 25%;
        }
        
        @include media-breakpoint-down(sm) {
          padding-top: 37%;
        }
      }
    }
    
    .photo-journey__content__section__dotted-line {
      position: absolute;
      top: 0;
      left: 40%;
      bottom: 0;
      display: block;
      width: 65%;
      height: 100%;
      background: url(../../images/dotted-line.svg) no-repeat center center;
      background-size: 100% auto;
      z-index: $z-index-low;
      display: flex;
      justify-content: center;
    }
    
    .photo-journey__content__section__full-width {
      position: relative;
      z-index: $z-index-medium;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      
      .photo-summary {
        width: 80%;
        text-align: center;
        
        .photo-summary__diagram {
          position: relative;
          width: 100%;
          height: 120px;
          background: url(../../images/dotted-line.png) no-repeat center center;
          background-size: auto 4px;
          margin: 0 0 $grid-gutter-width 0;
          
          @include media-breakpoint-up(lg) {
            background-size: auto 8px;
          }
          
          &::before,
          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 230px;
            background: black;
            z-index: $z-index-low;
            background: linear-gradient(90deg, rgba(247,247,243,1) 50%, rgba(247,247,243,0) 100%);
          }
          
          &::after {
            left: auto;
            right: 0;
            background: linear-gradient(-90deg, rgba(247,247,243,1) 50%, rgba(247,247,243,0) 100%);
          }
          
          @include media-breakpoint-up(lg) {
            height: 230px;
          }
          
          .photo-summary__diagram__body {
            position: relative;
            display: flex;
            justify-content: space-between;
            justify-content: center;
            z-index: $z-index-medium;
          }
          
          .photo-summary__diagram__device {
            position: relative;
            display: block;
            height: 120px;
            flex: 1;
            background-color: transparent;
            background-repeat: no-repeat;
            background-size: auto 50%;
            background-position: center center;
            
            @include media-breakpoint-up(lg) {
              height: 230px;
            }
            
            &.photo-summary__diagram__device--index-0,
            &.photo-summary__diagram__device--index-2 {
              .person-profile {
                top: auto;
                bottom: 15px;
              }
            }
            
            .person-profile {
              position: absolute;
              top: 0;
              right: 0;
            }
            
            &.photo-summary__diagram__device--phone-suzi {
              background-image: url(../../images/phone-with-image.png);
              background-size: auto 65%;
            }
            
            &.photo-summary__diagram__device--phone {
              background-image: url(../../images/phone-with-image.png);
            }
            
            &.photo-summary__diagram__device--laptop {
              background-image: url(../../images/laptop-with-image.png);
              background-size: 70% auto;
            }
            
            &.photo-summary__diagram__device--tablet {
              background-image: url(../../images/tablet-with-image.png);
              background-size: 70% auto;
            }
          }
          
          .photo-summary__diagram__photo-circle {
            display: block;
            flex: 1;
            background: url(../../images/photo-circle.png) no-repeat center center;
            background-size: 80% auto;
            height: 120px;
            
            @include media-breakpoint-up(lg) {
              height: 230px;
            }
          }

          
          .photo-summary__diagram__person-1,
          .photo-summary__diagram__person-2 {
            position: absolute;
            display: block;
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }
          
          .photo-summary__diagram__person-1 {
            top: 0;
            left: 35%;
          }
          
          .photo-summary__diagram__person-2 {
            top: 50%;
            left: 65%;
          }
        }
        
        h1 {
          padding: 0 10%;
          
          @include media-breakpoint-down(md) {
            @include font-extra-bold(($font-size-large*0.8), 1.2);
          }
        }
        
        .photo-summary__buttons {
          margin-top: $grid-gutter-width;
        }
      }
    }
    
    .photo-wrapper {
      display: block;
      width: 80px;
      height: 100%;
      transform-origin: bottom center;
      perspective: 800px;
      padding-top: 50%;
      
      @include media-breakpoint-up(md) {
        padding-top: 38%;
      }
      
      @include media-breakpoint-up(lg) {
        padding-top: 160px;
        width: 150px;
        height: 100%;
      }
      
      .photo {
        width: 80px;
        height: 80px;
        background: url(../../images/photo.png) no-repeat center center;
        background-size: 100% 100%;
      	animation-name: photo-move;
      	animation-duration: 2s;
      	animation-iteration-count: 1;
      	animation-timing-function: ease-in-out;
      	animation-fill-mode: forwards;
      	
      	@include media-breakpoint-up(lg) {
          width: 150px;
          height: 150px;
      	}
      }
    }
    
    .device {
      position: relative;
      display: block;
      margin-right: ($grid-gutter-width*2);
      
      @include media-breakpoint-up(lg) {
        margin-right: ($grid-gutter-width*2);
      }
      
      &.device--selfie-phone {
        width: 200px;
        min-width: 200px;
        height: 420px;
        transition: all 0.25s ease-in-out;
        z-index: $z-index-medium;
        
        @include media-breakpoint-up(lg) {
          width: 286px;
          min-width: 286px;
          height: 600px;
        }
        
        &.device--selfie-phone--showing-camera-app {
          transform: translateX(50%);
          margin-right: 0;
          
          .device__display {
            .device__display__toolbar {
              transform: translateY(0);
            }
          }
        }
        
        &.device--selfie-phone--has-taken-photo {
          transform: translateX(0);
          margin-right: ($grid-gutter-width*2);
          transition-delay: 1s;
          transition-timing-function: ease-in-out;
          
          @include media-breakpoint-up(lg) {
            margin-right: ($grid-gutter-width*2);
          }
          
          .device__display {
            .device__display__image {
              animation-name: none;
              transform: scale(1.3);
            	animation-name: flash;
            	animation-duration: 0.1s;
            	animation-iteration-count: 1;
            	animation-fill-mode: forwards;
            	animation-timing-function: linear;
            }
            
            .device__display__toolbar {
              transform: translateY(100%);
              transition-delay: 0.5s;
              
              .device__display__toolbar__button {
                &::after {
                  content: '';
                	animation-name: scale-and-fade;
                	animation-duration: 0.35s;
                	animation-iteration-count: 1;
                	animation-fill-mode: forwards;
                	animation-timing-function: ease-out;
                }
              }
            }
          }
        }
        
        .device__display {
          bottom: 45px;
          top: 40px;
          left: 8px;
          right: 8px;
          z-index: $z-index-medium;
          overflow: hidden;
          border-radius: 5px;
          
          @include media-breakpoint-up(lg) {
            bottom: 70px;
            top: 70px;
            left: 13px;
            right: 13px;
          }
          
          .device__display__image {
            top: -10px;
            left: -10px;
            right: -10px;
            bottom: -10px;
            background-image: url(../../images/selfie.png);
          	animation-name: shakey-camera;
          	animation-duration: 10s;
          	transform-origin:50% 50%;
          	animation-iteration-count: infinite;
          	animation-timing-function: ease-in-out;
          	animation-direction: alternate;
          }
        }
        
        .device__bezel {
          z-index: $z-index-medium - 5;
          background-image: url(../../images/phone.svg);
        }
      }
      
      &.device--phone {
        width: 200px;
        min-width: 200px;
        height: 420px;
        transition: all 0.25s ease-in-out;
        z-index: $z-index-medium;
        
        @include media-breakpoint-up(lg) {
          width: 286px;
          min-width: 286px;
          height: 600px;
        }
        
        &.device--selfie-phone--has-taken-photo {
          transform: translateX(0);
          margin-right: ($grid-gutter-width*2);
          transition-delay: 1s;
          transition-timing-function: ease-in-out;
          margin-right: ($grid-gutter-width*2);
        }
        
        .device__display {
          bottom: 45px;
          top: 40px;
          left: 8px;
          right: 8px;
          z-index: $z-index-medium;
          overflow: hidden;
          border-radius: 5px;
          
          @include media-breakpoint-up(lg) {
            bottom: 70px;
            top: 70px;
            left: 13px;
            right: 13px;
          }
          
          .device__display__image {
            top: -10px;
            left: -10px;
            right: -10px;
            bottom: -10px;
            background-image: url(../../images/selfie.png);
            transform: scale(0);
            transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
          }
        }
        
        .device__bezel {
          z-index: $z-index-medium - 5;
          background-image: url(../../images/phone.svg);
        }
      }

      &.device--phone,      
      &.device--laptop,
      &.device--tablet {
        .person-profile {
          position: absolute;
          bottom: 0;
          right: 0;
          //background-color: red;
          z-index: $z-index-medium + 20;
          width: 80px;
          height: 80px;
          pointer-events: none;
          transform: translateX(25%) translateY(25%);
          
          @include media-breakpoint-up(lg) {
            width: 250px;
            height: 250px;
          }
        }        
      }
      
      &.device--laptop {
        width: 300px;
        min-width: 300px;
        height: 160px;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
        
        @include media-breakpoint-up(lg) {
          transform: translateX(-15%);
          width: 750px;
          min-width: 750px;
          height: 405px;
        }
        
        .device__display {
          bottom: 15px;
          top: 5px;
          left: 30px;
          right: 30px;
          background: map-get($theme-colours, white);
          
          @include media-breakpoint-up(lg) {
            bottom: 40px;
            top: 20px;
            left: 80px;
            right: 80px;
          }
          
          .device__display__image {
            background: url(../../images/selfie-zoomed.png) no-repeat center center;
            background-size: cover;
            transform: scale(0);
            transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
          }
        }
        
        .device__bezel {
          background-image: url(../../images/laptop.svg);
        }
      }
      
      &.device--tablet {
        width: 300px;
        min-width: 300px;
        height: 200px;
        transform: rotate(-10deg);
        
        @include media-breakpoint-up(lg) {
          width: 550px;
          min-width: 550px;
          height: 370px;
        }
        
        .device__display {
          bottom: 15px;
          top: 10px;
          left: 15px;
          right: 15px;
          background: map-get($theme-colours, white);
          
          @include media-breakpoint-up(lg) {
            bottom: 30px;
            top: 30px;
            left: 30px;
            right: 30px;
          }
          
          .device__display__image {
            background: url(../../images/selfie-zoomed.png) no-repeat center center;
            background-size: cover;
            transform: scale(0);
            transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
          }
        }
        
        .device__bezel {
          background-image: url(../../images/tablet.svg);
        }
        
        .person-profile {
          transform: translateX(25%) translateY(25%) rotate(10deg);
        }
      }

      &.device--social-media {
        width: 300px;
        min-width: 300px;
        height: 300px;
        transition: all 0.25s ease-in-out;
        z-index: $z-index-medium;
        transform: translateX(50%);
        margin-right: 0;
        
        @include media-breakpoint-up(lg) {
          width: 500px;
          min-width: 500px;
          height: 500px;
        }
        
        &.device--social-media--showing-message {
          transform: translateX(0);
          margin-right: ($grid-gutter-width*2);
          transition-delay: 3s;
          transition-timing-function: ease-in-out;
          
          .device__people {
          	animation-name: spin;
          	animation-duration: 10s;
          	animation-iteration-count: infinite;
          	animation-timing-function: linear;
            
            .person-profile-wrapper {
            	.person-profile {
              	opacity: 1;
              	animation-name: spin;
              	animation-duration: 10s;
              	animation-iteration-count: infinite;
              	animation-timing-function: linear;
              	animation-direction: reverse;
            	}
            }
            
            .person-profile {
              opacity: 1;
            }
          }
        }
        
        .device__people {
          width: 100%;
          height: 100%;
          
          .person-profile-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            //transform: translateX(-50%) translateY(-50%);
            margin-top: -($profile-picker-size-sm/2);
            margin-left: -($profile-picker-size-sm/2);
            
            @include media-breakpoint-up(lg) {
              margin-top: -($profile-picker-size/2);
              margin-left: -($profile-picker-size/2);
            }
            
            &.person-profile-wrapper--selected {
              z-index: $z-index-medium + 30;
            }

            .person-profile {
              opacity: 0;
              transition: opacity 0.25s ease-in-out;
              
              &.person-profile--selected {
                opacity: 1;
              }
            }
          }
        }
        
        .device__photo {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 150px;
          height: 150px;
          margin-top: -(150px/2);
          margin-left: -(150px/2);
          
          @include media-breakpoint-up(lg) {
            width: 300px;
            height: 300px;
            margin-top: -(300px/2);
            margin-left: -(300px/2);
          }
          
          .photo {
            width: 100%;
            height: 100%;
            background: url(../../images/photo.png) no-repeat center center;
            background-size: 100% 100%;
            transform: rotate(10deg);
          	animation-name: tilt;
          	animation-duration: 5s;
          	animation-iteration-count: infinite;
          	animation-timing-function: ease-in-out;
          	animation-direction: alternate;
          }
        }
      }

      .device__display {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $z-index-medium;
        overflow: hidden;
        
        .device__display__toolbar {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          padding: 15px 0;
          text-align: center;
          background: map-get($theme-colours, orange);
          color: map-get($theme-colours, white);
          z-index: $z-index-medium + 20;
          transform: translateY(100%);
          transition: transform 0.25s ease-in-out;
          
          .device__display__toolbar__button {
            position: relative;
            display: block;
            width: 40px;
            height: 40px;
            border: none;
            margin: 0 0 10px 0;
            border-radius: 50%;
            background: map-get($theme-colours, white);
            
            @include media-breakpoint-up(lg) {
              width: 58px;
              height: 58px;
            }
            
            &::after {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              //content: '';
              display: block;
              background: map-get($theme-colours, white);
              border-radius: 50%;
              pointer-events: none;
            }
            
            &:hover,
            &:focus {
              outline: none;
              cursor: pointer;
            }
            
            span {
              display: block;
              width: 0;
              height: 0;
              overflow: hidden;
              text-align: left;
              text-indent: -99999px;
            }
          }
          
          .device__display__toolbar__label {
            width: 100%;
            margin: 0;
            padding: 0;
            
            @include font-extra-bold($font-size-normal, 1);
          }
        }
        
        .device__preloader {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 150px;
          height: 20px;
          margin-top: -(20px/2);
          margin-left: -(150px/2);
          background: map-get($theme-colours, primary);
          z-index: $z-index-medium;
          
          @include media-breakpoint-up(lg) {
            width: 200px;
            margin-left: -(200px/2);
          }
          
          span {
            display: block;
            height: 100%;
            background: map-get($theme-colours, accent);
            width: 0%;
            transition: width 2s ease-in;
          }
        }
        
        .device__display__image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: $z-index-medium + 10;
        }
      }
      
      .device__bezel {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        z-index: $z-index-medium + 10;
      }
    }
  }
}

.profile-picker-wrapper {
  width: ($profile-picker-size-sm*4);
  
  @include media-breakpoint-up(lg) {
    width: ($profile-picker-size*4);
  }
  
  h2 {
    text-align: center;
    padding: 0;
    margin: 0 0 ($grid-gutter-width) 0;
    min-height: 100px;
    
    @include font-extra-bold(($font-size-medium*1.2), 1.2);
    
    @include media-breakpoint-up(lg) {
      padding: 0 15%;
    }
  }
}

.profile-picker {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
  li {
    margin: 0;
    padding: 10px;
  }
  
  .person-profile {
    &.person-profile--selected {
      animation: poke 1s linear;
      animation-iteration-count: 1;
    }
    
    &.person-profile--disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

.person-profile-wrapper,
.person-profile {
  position: relative;
  border: none;
  outline: none;
  display: block;
  width: ($profile-picker-size-sm - 20px);
  height: ($profile-picker-size-sm - 20px);
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center center;
  border-radius: 50%;
  box-shadow: none;
  text-align: left;
  //overflow: hidden;
  //text-indent: -9999px;
  outline: none;
  
  @include media-breakpoint-up(lg) {
    width: ($profile-picker-size - 20px);
    height: ($profile-picker-size - 20px);
  }
  
  &:hover {
    .person-profile__tooltip {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
    }
  }
  
  &.person-profile--selected {
    .person-profile__tooltip {
      opacity: 0;
    }
  }
  
  .person-profile__device {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: map-get($theme-colours, white);
    border: solid 4px map-get($theme-colours, very-light-grey);
    background-repeat: no-repeat;
    background-size: auto 45%;
    background-position: center center;
    
    &.person-profile__device--phone {
      background-image: url(../../images/phone.svg);
      background-size: auto 65%;
    }
    
    &.person-profile__device--laptop {
      background-image: url(../../images/laptop.svg);
    }
    
    &.person-profile__device--tablet {
      background-image: url(../../images/tablet.svg);
    }
    
    svg {
      max-height: 20px;
      width: auto;
    }
  }
  
  .person-profile__tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    display: block;
    background: map-get($theme-colours, primary);
    color: map-get($theme-colours, white);
    padding: 5px 10px;
    margin: 0;
    transform: translateX(-50%) translateY(5px);
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    
    @include font-bold($font-size-normal, 1);
    
    svg {
      max-height: 20px;
      width: auto;
      margin-right: 10px;
    }
    
    .device-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: red;
      vertical-align: middle;
      overflow: hidden;
      text-indent: -99999px;
      text-align: left;
      font-style: normal;
    }
    
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 7px 0 7px;
      margin-left: -7px;
      border-color: map-get($theme-colours, primary) transparent transparent transparent;
    }
  }
  
  &:hover,
  &:focus {
    outline: none;
  }
  
  &.person-profile--person1 {
    background-image: url(../../images/profile-1.png);
    
    &.person-profile--selected {
      background-image: url(../../images/profile-1-selected.png);
    }
  }
  
  &.person-profile--person2 {
    background-image: url(../../images/profile-2.png);
    
    &.person-profile--selected {
      background-image: url(../../images/profile-2-selected.png);
    }
  }
  
  &.person-profile--person3 {
    background-image: url(../../images/profile-3.png);
    
    &.person-profile--selected {
      background-image: url(../../images/profile-3-selected.png);
    }
  }
  
  &.person-profile--person4 {
    background-image: url(../../images/profile-4.png);
    
    &.person-profile--selected {
      background-image: url(../../images/profile-4-selected.png);
    }
  }
  
  &.person-profile--person5 {
    background-image: url(../../images/profile-5.png);
    
    &.person-profile--selected {
      background-image: url(../../images/profile-5-selected.png);
    }
  }
  
  &.person-profile--person6 {
    background-image: url(../../images/profile-6.png);
    
    &.person-profile--selected {
      background-image: url(../../images/profile-6-selected.png);
    }
  }
  
  &.person-profile--person7 {
    background-image: url(../../images/profile-7.png);
    
    &.person-profile--selected {
      background-image: url(../../images/profile-7-selected.png);
    }
  }
  
  &.person-profile--person8 {
    background-image: url(../../images/profile-8.png);
    
    &.person-profile--selected {
      background-image: url(../../images/profile-8-selected.png);
    }
  }
}
  