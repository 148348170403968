/* Debug
---------------------------------------------------------------------------------------------------- */

/*
body::after {
  content: 'default / xs';
  position: fixed;
  bottom: 5px;
  right: 5px;
  height: 10px;
  line-height: 10px;
  text-align: left;
  font-size: 10px;
  color: black;
  z-index: $z-index-top;
  opacity: 0.8;
  
  @include media-breakpoint-down(xs) {
    content: 'xs';
  }
  
  @include media-breakpoint-up(sm) {
    content: 'sm';
  }
  
  @include media-breakpoint-up(md) {
    content: 'md';
  }
  
  @include media-breakpoint-up(lg) {
    content: 'lg';
  }
  
  @include media-breakpoint-up(xl) {
    content: 'xl';
  }
}
*/

/* Global Elements
---------------------------------------------------------------------------------------------------- */

html,
body {
  background: map-get($theme-colours, very-light-grey);
  color: map-get($theme-colours, primary);
  font-size: $font-size-root;
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

#main {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: $header-height-xs;
  height: 100vh;
  min-height: 600px;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  
  @include media-breakpoint-up(sm) {
    min-height: 960px;
    padding-top: ($header-height/2); // Offset by half header height to ensure it is optically centred
  }
  
  @include media-breakpoint-up(md) {
    min-height: 660px;
  }
  
  @include media-breakpoint-up(lg) {
    min-height: 800px;
  }
  
  @include media-breakpoint-up(xl) {
    //
  }
  
  &.main--who-can-help-home {
    @include media-breakpoint-down(md) {
      height: auto;
      justify-content: flex-start;
      padding: ($grid-gutter-width*3) 0 ($grid-gutter-width*2) 0;
    }
  }
  
  &.main--game-menu,
  &.main--summary {
    height: auto;
    justify-content: flex-start;
  }
  
  &.main--technology-plan {
    @include media-breakpoint-down(xs) {
      height: auto;
      justify-content: flex-start;
    }
  }
  
  &.main--inactive {
    pointer-events: none;
    opacity: 0.7 !important;
  }
  
  &.main--share-that-photo {
    min-width: 768px;
  }
}
