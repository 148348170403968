$picker-menu-image-height: 320px;
$picker-menu-image-height-sm: 260px;
$picker-menu-image-height-xs: 200px;

.picker-menu-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background: rgba(255, 255, 255, 0.5);
  z-index: $z-index-top;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .picker-menu-modal__close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    padding: 0;
    margin: 0;
    text-align: left;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: map-get($theme-colours, primary);
    transform: translateX(50%) translateY(-25%);
    
    span {
      position: relative;
      z-index: $z-index-medium;
      display: block;
      width: 100%;
      height: 100%;
      background: url(../../images/icon-cross-white.svg) no-repeat center center;
      background-size: 60% auto;
      overflow: hidden;
      text-indent: -9999px;
    }
    
    &::before {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      content: '';
      display: block;
      border-radius: 50%;
      background: map-get($theme-colours, accent);
      z-index: $z-index-low;
      transform: scale(0);
      opacity: 0;
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-out;
    }
    
    &:hover,
    &:focus {
      outline: none;
      cursor: pointer;
      
      &::before {
        transform: scale(1);
        opacity: 1;
        transition: opacity 0.15s ease-in-out, transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
  }
  
  .picker-menu-modal__body {
    position: relative;
    width: 650px;
    z-index: $z-index-high;
    background: map-get($theme-colours, white);
    border-radius: 50px;
    padding: 0 20px;
    
    @include media-breakpoint-up(lg) {
      width: 700px;
    }
  }
  
  .picker-menu {
    padding: 0;
    
    li {
      padding: 0 5px;
    }
  }
  
  .picker-button {
    padding-top: ($picker-menu-image-height-xs*0.65);
    
    @include media-breakpoint-up(sm) {
      padding-top: ($picker-menu-image-height-sm*0.65);
    }
    
    @include media-breakpoint-up(lg) {
      padding-top: ($picker-menu-image-height*0.65);
    }
    
    .picker-button__image {
      //height: ($picker-menu-image-height-xs*0.85);
      
      @include media-breakpoint-up(sm) {
        //height: ($picker-menu-image-height-sm*0.85);
      }
      
      @include media-breakpoint-up(lg) {
        //height: ($picker-menu-image-height*0.85);
      }
      
      svg {
        max-width: 50%;
        height: auto;
      }
    }
    
    .picker-button__label {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  
  .picker-menu-modal__footer {
    margin: 0;
    margin: 0 0 $grid-gutter-width 0;
    
    .picker-menu-modal__finish {
      display: block;
      width: 100%;
      border: none;
    }
  }
}

.picker-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: -($grid-gutter-width);
  
  @include media-breakpoint-up(sm) {
    margin: 0 (-($grid-gutter-width/2));
  }
  
  &.picker-menu--person-picker {
    li {
      @include media-breakpoint-up(lg) {
        width: (100% /3);
      }
    }
  }
  
  li {
    padding: 0;
    margin: 0 0 $grid-gutter-width 0;
    width: (100% /2);
    
    @include media-breakpoint-up(sm) {
      padding: 0 ($grid-gutter-width/2);
    }
    
    @include media-breakpoint-up(lg) {
      width: (100% /3);
    }
  }
}

.picker-button {
  padding-top: $picker-menu-image-height-xs;
  position: relative;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  //overflow: hidden;
  
  @include media-breakpoint-up(sm) {
    padding-top: $picker-menu-image-height-sm;
  }
  
  @include media-breakpoint-up(lg) {
    padding-top: $picker-menu-image-height;
  }
  
  &.picker-button--completed {
    opacity: 0.3;
    pointer-events: none;
  }
  
  .summary-list__item & {
    //padding-top: ($picker-menu-image-height-xs*0.85);
    
    @include media-breakpoint-up(sm) {
      //padding-top: ($picker-menu-image-height-sm*0.85);
    }
    
    @include media-breakpoint-up(lg) {
      //padding-top: ($picker-menu-image-height*0.85);
    }
  }

  &.picker-button--completed,
  &.picker-button--selected,
  &:hover {
    .picker-button__image {
      //
    }
    
    .picker-button__label {
      color: map-get($theme-colours, white);
      
      &::before {
        transform: scale(1);
      }
    }
  }
  
  &.picker-button--selected {
    pointer-events: none;
    
    &.picker-button--is-incorrect {
      .picker-button__image--selected {
	      animation: shake 0.8s linear;
	      animation-delay: 1s;
	      animation-iteration-count: 1;
      }
    }
    
    .picker-button__image {
      &.picker-button__image--normal {
        animation-name: zoom-out;
        animation-duration: 0.8s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        pointer-events: none;
        z-index: $z-index-low + 10;
      }
      
      &.picker-button__image--selected {
        display: block;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  
  &.picker-button--disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  
  .picker-button__tick {
    opacity: 0;
    position: absolute;
    top: 25%;
    left: 50%;
    display: block;
    width: 130px;
    height: 130px;
    margin-left: -(130px/2);
    z-index: $z-index-high;
    animation-name: spring-in;
    animation-duration: 3000ms;
    animation-delay: 500ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    
    svg {
      width: 100%;
      height: auto;
      
      #tick-378 {
        animation-name: tada;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
      }
    }
  }
    
  .picker-button__image {
    position: absolute;
    top: 0;
    left: $grid-gutter-width;
    right: $grid-gutter-width;
    padding-top: 30px;
    height: $picker-menu-image-height-xs;
    overflow: hidden;
    text-align: center;
    transition: opacity 0.25s ease-in-out, transform 0.35s ease-in-out;
    z-index: $z-index-low;
    
    @include media-breakpoint-up(sm) {
      left: 0;
      right: 0;
      height: $picker-menu-image-height-sm;
    }
    
    @include media-breakpoint-up(lg) {
      height: $picker-menu-image-height;
    }
    
    .picker-menu-modal & {
      height: ($picker-menu-image-height-xs*0.6);
      
      @include media-breakpoint-up(sm) {
        height: ($picker-menu-image-height-sm*0.6);
      }
      
      @include media-breakpoint-up(lg) {
        height: ($picker-menu-image-height*0.6);
      }
    }
    
    &.picker-button__image--selected {
      pointer-events: none;
      opacity: 0;
      transform: scale(1.2);
      transition: opacity 0.5s ease-in-out, transform 0.35s ease-out;
    }
    
    svg {
      max-width: 100%;
      height: auto;
    }
  }
  
  .picker-button__label {
    position: relative;
    overflow: hidden;
    display: block;
    background: map-get($theme-colours, accent);
    color: map-get($theme-colours, primary);
    text-align: center;
    margin: 0 ($grid-gutter-width/2);
    padding: ($grid-gutter-width/2);
    margin-top: -30px;
    border-radius: ($grid-gutter-width/2);
    z-index: $z-index-medium;
    transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
    
    @include font-extra-bold($font-size-normal, 1);
    
    @include media-breakpoint-up(sm) {
      margin: 0 ($grid-gutter-width/2);
      margin-top: -50px;
      
      @include font-extra-bold($font-size-medium, 1);
    }
    
    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 0;
    }
    
    .picker-menu--person-picker & {
      @include media-breakpoint-up(lg) {
        //margin-left: $grid-gutter-width;
        //margin-right: $grid-gutter-width;
      }
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: map-get($theme-colours, purple);
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
      transform: scale(0);
      border-radius: ($grid-gutter-width/2);
    }
    
    span {
      position: relative;
    }
    
    @include media-breakpoint-up(xl) {
      margin-left: 0; //($grid-gutter-width*2);
      margin-right: 0; //($grid-gutter-width*2);
    }
  }
  
  .picker-button__button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: transparent;
    border: none;
    outline: none;
    z-index: $z-index-high;
  }
}
