.add-rule {
  padding: 0 5%;
  position: relative;
  z-index: $z-index-medium;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @include media-breakpoint-down(xs) {
    margin-bottom: ($grid-gutter-width*2);
  }
  
  @include media-breakpoint-up(lg) {
    padding: 0 15%;
  }
  
  br {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  
  .add-rule__header {
    text-align: center;
    margin: 0;
    
    .title {
      margin: 0 0 ($grid-gutter-width/2) 0;
    }
    
    .subtitle {
      margin: 0;
      padding: 0 ($grid-gutter-width*2);
    }
  }
  
  .add-rule__body {
    text-align: center;
    margin: ($grid-gutter-width) 0;
    
    @include font-regular($font-size-medium, 1.4);
    
    @include media-breakpoint-up(sm) {
      @include font-regular($font-size-large, 1.4);
    }
  }
  
  .add-rule__footer {
    text-align: center;
    margin: ($grid-gutter-width*2) 0 0 0;
    
    @include media-breakpoint-down(xs) {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: map-get($theme-colours, white);
      padding: ($grid-gutter-width/2) 0;
      height: auto;
    }
  }
}

.rule-option-wrapper {
  .rule-option-wrapper__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: $z-index-top + 10;
    background: rgba(0, 0, 0, 0.3);
  }
}

.rule-option {
  @include media-breakpoint-up(sm) {
    position: relative;
  }
  
  .rule-option__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
      max-width: 27px;
      height: auto;
      fill: currentColor;
      
      * {
        fill: currentColor;
      }
    }
  }
  
  .rule-option__button {
    position: relative;
    border: none;
    padding: 0 10px 10px 10px;
    margin: 0 -5px;
    background-color: transparent;
    border-radius: 10px;
    color: map-get($theme-colours, medium-grey);
    
    &::after {
      content: '';
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 5px;
      height: 4px;
      background-color: transparent;
      background-repeat: repeat-x;
      background-size: auto 4px;
      background-position: bottom left;
      background-image: url(../../images/thick-dash.png);
    }
    
    &:hover {
      cursor: pointer;
      background-color: map-get($theme-colours, white);
    }
    
    &:focus {
      outline: none;
    }
    
    .icon {
      display: inline-block;
      margin-right: 10px;
      
      svg {
        max-width: 30px;
        height: auto;
        fill: currentColor;
        
        * {
          fill: currentColor;
        }
      }
    }
    
    .key-colour-orange &,
    &.rule-option__button--orange {
      color: map-get($theme-colours, orange);
      
      &::after {
        background-image: url(../../images/thick-dash-orange.png);
      }
    }
    
    .key-colour-purple &,
    &.rule-option__button--purple {
      color: map-get($theme-colours, purple-light);
      
      &::after {
        background-image: url(../../images/thick-dash-purple.png);
      }
    }
    
    .key-colour-green &,
    &.rule-option__button--green {
      color: map-get($theme-colours, green);
      
      &::after {
        background-image: url(../../images/thick-dash-green.png);
      }
    }
    
    .key-colour-red &,
    &.rule-option__button--red {
      color: map-get($theme-colours, red);
      
      &::after {
        background-image: url(../../images/thick-dash-red.png);
      }
    }
    
    .key-colour-blue &,
    &.rule-option__button--blue {
      color: map-get($theme-colours, blue);
      
      &::after {
        background-image: url(../../images/thick-dash-blue.png);
      }
    }
  }
  
  ul {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: $z-index-top + 20;
    list-style: none;
    background: map-get($theme-colours, white);
    margin: 0;
    padding: 0;
    width: 250px;
    transform: translateY(-50%);
    overflow: hidden;
    
    @include media-breakpoint-down(xs) {
      left: 50%;
      margin-left: -(250px/2);
      transform: translateY(0);
    }
    
    @include media-breakpoint-up(lg) {
      width: 350px;
    }
    
    li {
      margin: 0;
      padding: 0;
      display: flex;
      
      
      .rule-option__icon {
        width: 30px;
        margin-left: 15px;
        align-items: center;
      }
      
      &:first-child {
        padding-top: ($grid-gutter-width/2);
        
        @include media-breakpoint-up(lg) {
          padding-top: $grid-gutter-width;
        }
        
        button {
          //padding-top: ($grid-gutter-width/2);
          
          @include media-breakpoint-up(lg) {
            //padding-top: $grid-gutter-width;
          }
        }
      }
      
      &:last-child {
        padding-bottom: ($grid-gutter-width/2);
        
        @include media-breakpoint-up(lg) {
          padding-bottom: $grid-gutter-width;
        }
        
        button {
          //padding-bottom: ($grid-gutter-width/2);
          
          @include media-breakpoint-up(lg) {
            //padding-bottom: $grid-gutter-width;
          }
        }
      }
      
      &.selected {
        .rule-option__icon,
        button {
          .key-colour-orange & {
            color: map-get($theme-colours, orange);
          }
          
          .key-colour-purple & {
            color: map-get($theme-colours, purple-light);
          }
          
          .key-colour-green & {
            color: map-get($theme-colours, green);
          }
          
          .key-colour-red & {
            color: map-get($theme-colours, red);
          }
          
          .key-colour-blue & {
            color: map-get($theme-colours, blue);
          }
          
          &::after {
            content: '';
          }
          
          &:hover {
            cursor: default;
          }
        }
      }
      
      button {
        position: relative;
        background: map-get($theme-colours, white);
        padding: 10px ($grid-gutter-width/2);
        padding-right: 30px;
        border: none;
        text-align: left;
        width: 100%;
        outline: none;
        flex: 1;
        
        @include font-regular($font-size-medium, 1);
        
        @include media-breakpoint-up(lg) {
          padding: 10px $grid-gutter-width;
          padding-right: 60px;
          
          @include font-regular($font-size-large, 1);
        }
        
        &::after {
          //content: '';
          position: absolute;
          top: 50%;
          right: 10px;
          display: block;
          width: 20px;
          height: 20px;
          margin-top: -(20px/2);
          background: url(../../images/icon-button-tick.svg) no-repeat center center;
          background-size: 100% auto;
          
          @include media-breakpoint-up(lg) {
            width: 30px;
            height: 30px;
            margin-top: -(30px/2);
            right: 20px;
          }
        }
        
        &:hover {
          color: map-get($theme-colours, medium-grey);
        }
        
        &:focus {
          outline: none;
        }
      }
    }
  }
}
