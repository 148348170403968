.add-people {
  display: flex;
  flex-wrap: wrap;
  padding: ($grid-gutter-width) 0;
  position: relative;
  z-index: $z-index-medium;
  
  @include media-breakpoint-up(sm) {
    flex-wrap: nowrap;
  }
  
  .add-people__left {
    width: 100%;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 ($grid-gutter-width*2) 0;
    
    @include media-breakpoint-up(sm) {
      width: 50%;
      padding: 0 ($grid-gutter-width) 0 0;
      margin: 0;
    }
    
    @include media-breakpoint-up(md) {
      padding: 0 ($grid-gutter-width);
    }
    
    @include media-breakpoint-up(lg) {
      padding: 0 ($grid-gutter-width*2);
    }
    
    @include media-breakpoint-up(xl) {
      padding: 0 ($grid-gutter-width*3);
    }
  }
  
  .add-people__right {
    position: relative;
    width: 100%;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: $z-index-medium;
    
    @include media-breakpoint-up(sm) {
      width: 50%;
      padding: 0 0 0 ($grid-gutter-width/2);
    }
    
    @include media-breakpoint-up(md) {
      padding: 0 ($grid-gutter-width*2);
    }
    
    &.people__right--disabled {
      .button {
        pointer-events: none;
        opacity: 0.4;
      }
    }
    
    p {
      margin: 0;
      padding: 0;
      color: map-get($theme-colours, medium-grey);
    }
  }
  
  .add-people__left__header {
    text-align: center;
    margin: ($grid-gutter-width*2) 0 ($grid-gutter-width) 0;
    
    @include media-breakpoint-up(sm) {
      margin: 0 0 ($grid-gutter-width) 0;
    }
    
    @include media-breakpoint-up(md) {
      margin: 0 0 ($grid-gutter-width*2) 0;
    }
    
    .title {
      margin: 0 0 ($grid-gutter-width/2) 0;
      
      &::before {
        content: '';
        display: block;
        margin: 0 auto $grid-gutter-width auto;
        width: 50px;
        height: 50px;
        background: url(../../images/icon-house.svg) no-repeat center center;
        background-size: auto 100%;
        
        @include media-breakpoint-up(sm) {
          width: 90px;
          height: 90px;
        }
      }
    }
    
    .subtitle {
      margin: 0;
      padding: 0;
      
      @include media-breakpoint-up(lg) {
        padding: 0 ($grid-gutter-width*2);
      }
    }
  }
  
  .add-people__left__form {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    
    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
      height: 55px;
    }
    
    &.add-people__left__form--focus {
      .input-wrapper {
        &::before {
          width: 100%;
          opacity: 1;
        }
      }
    }
    
    &.add-people__left__form--disabled {
      .button {
        pointer-events: none;
        opacity: 0.4;
      }
    }
    
    &.add-people__left__form--orange {
      .input-wrapper {
        &::before {
          background-color: map-get($theme-colours, orange);
        }
      }
    }
    
    &.add-people__left__form--purple {
      .input-wrapper {
        &::before {
          background-color: map-get($theme-colours, purple-light);
        }
      }
    }
    
    &.add-people__left__form--green {
      .input-wrapper {
        &::before {
          background-color: map-get($theme-colours, green);
        }
      }
    }
    
    &.add-people__left__form--red {
      .input-wrapper {
        &::before {
          background-color: map-get($theme-colours, red);
        }
      }
    }
    
    &.add-people__left__form--blue {
      .input-wrapper {
        &::before {
          background-color: map-get($theme-colours, blue);
        }
      }
    }
    
    .input-wrapper {
      position: relative;
      flex: auto;
      margin: 0 0 ($grid-gutter-width/2) 0;
      width: 100%;
      
      @include media-breakpoint-up(md) {
        width: auto;
        margin: 0 ($grid-gutter-width/2) 0 0;
      }
      
      input {
        width: 100%;
        padding: 10px 0 (10px + 8px) 0;
        border: none;
        background: none;
        color: map-get($theme-colours, primary);
        
        @include font-extra-bold($font-size-medium, 1);
        
        &:focus {
          outline: none;
        }
        
        &::placeholder {
          color: map-get($theme-colours, light-grey);
        }
      }
      
      &::before,
      &::after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 8px;
        border-radius: 4px;
        background: map-get($theme-colours, accent);
      }
      
      &::before {
        background: map-get($theme-colours, pink);
        z-index: $z-index-medium;
        width: 0;
        opacity: 0;
        transition: all 0.25s ease-in-out;
      }
    }
    
    .form-tooltip {
      position: absolute;
      top: 100%;
      left: -10px;
      margin: 5px 0 0 0;
      padding: 10px 10px;
      border-radius: 10px;
      background: map-get($theme-colours, red);
      color: map-get($theme-colours, white);
      z-index: $z-index-high;
      
      @include font-bold($font-size-normal, 1.1);
      
      &::before {
        position: absolute;
        top: -8px;
        left: 10px;
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent  map-get($theme-colours, red) transparent;
      }
    }
    
    .button {
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin: 0;
        padding: 15px 15px;
      }
    }
  }
  
  .add-people__right__list {
    width: 100%;
    margin: 0; // 0 ($grid-gutter-width*2) 0;
    padding-bottom: $grid-gutter-width;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 60px);
    
    ul {
      list-style: none;
      padding: 0;
      margin: 0 (-($grid-gutter-width/2));
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      
      @include media-breakpoint-down(xs) {
        margin-bottom: ($grid-gutter-width*2);
      }
      
      li {
        text-align: center;
        margin: 0 ($grid-gutter-width/2);
        margin-bottom: 10px;
        
        .icon {
          color: map-get($theme-colours, accent);
          margin: 0 0 10px 0;
          
          &.icon--orange {
            color: map-get($theme-colours, orange);
          }
          
          &.icon--purple {
            color: map-get($theme-colours, purple-light);
          }
          
          &.icon--green {
            color: map-get($theme-colours, green);
          }
          
          &.icon--red {
            color: map-get($theme-colours, red);
          }
          
          &.icon--blue {
            color: map-get($theme-colours, blue);
          }
          
          &.icon--grey {
            color: map-get($theme-colours, light-grey);
          }
          
          svg {
            width: 60px;
            height: auto;
            
            * {
              fill: currentColor;
            }
          }
        }
        
        span {
          text-transform: capitalize;
          
          @include font-bold($font-size-medium, 1);
        }
      }
    }
  }
  
  .add-people__right__footer {
    margin-top: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 55px;
    
    @include media-breakpoint-down(xs) {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: map-get($theme-colours, white);
      padding: ($grid-gutter-width/2) 0;
      height: auto;
      
      .button {
        width: 100%;
        padding: 15px 15px;
      }
    }
  }
}

.tech-plan-detail {
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  z-index: $z-index-bottom;
  opacity: 0.2;
  
  .pyramid {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 440px;
    height: 500px;
    margin-top: -(500px/2);
    margin-left: -(440px/2);
    background: url(../../images/pyramid.svg) no-repeat center center;
    background-size: 100% 100%;
    
    @include media-breakpoint-up(sm) {
      width: 440px;
      height: 500px;
      margin-top: -(500px/2);
      margin-left: -(440px/2);
    }
  }
  
  .dashed-circle {
    position: absolute;
    top: 60%;
    left: 70%;
    
    svg {
      width: 90px;
      height: auto;
      animation-name: spin;
      animation-duration: 30s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      
      @include media-breakpoint-up(md) {
        width: 180px;
      }
    }
  }
  
  .striped-circle {
    position: absolute;
    top: 10%;
    left: 75%;
    
    svg {
      width: 350px;
      height: auto;
      animation-name: spin;
      animation-duration: 50s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-direction: reverse;
      
      #striped-circle {
        @for $i from 1 through 30 {
          :nth-child(#{$i}) {
            //animation-name: fade-out-and-in;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-delay: (100ms * $i);
          }
        }
      }
    }
  }
}
