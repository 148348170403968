h1,
h2,
h3 {
  @include font-display-bold($font-size-normal, 1.4);
}

ul,
ol,
p {
  @include font-regular($font-size-normal, 1.5);
  
  &:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  padding-left: 1rem;
}

li {
  margin: 0 0 0.6rem 0;
  
  @include font-regular($font-size-normal, 1.7);
  
  &:last-child {
    margin-bottom: 0;
  }
}

h1 {
  @include font-display-extra-bold(($font-size-large*0.8), 1.2);
  
  @include media-breakpoint-up(sm) {
    @include font-display-extra-bold($font-size-large, 1.2);
  }
}

h2 {
  @include font-display-bold($font-size-medium, 1.2);
}